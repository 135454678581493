@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

@layer components {
  .custom-container {
    @apply container mx-auto px-4;
  }
  .hover-custom {
    @apply transition-all duration-200 hover:scale-110 ease-in-out;
  }
}

* {
  font-family: "Poppins", sans-serif;
  scrollbar-width: none;
  scrollbar-color: #119ad9 transparent;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 0.25em;
  height: 0.25em;
}
::-webkit-scrollbar-thumb {
  background-color: #119ad9;
  border-radius: 10em;
}
::-webkit-scrollbar-track {
  background: transparent;
}
